import React from "react"
import { graphql } from "gatsby"
import styled, { keyframes } from "styled-components"
import { colors } from "../theme"
import Modal from "styled-react-modal"
import TextBlock from "./text-block"
import closeIcon from "../images/modal-close.png"
import { TranslationContext } from "../translate"

const backgroundAppear = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const ModalBackground = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  opacity: 1;
  background-color: ${colors.background.Dark97};
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 2em;
  animation: ${backgroundAppear} 400ms ease-out;
  overflow: auto;
`

const modalAppear = keyframes`
  0% { opacity: 0; transform: scale(0.95); }
  100% { opacity: 1;  transform: scale(1); }
`

const StyledModal = Modal.styled`
  width: 100%;
  max-width: 55em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: transparent;
  color: #ffffff;
  animation: ${modalAppear} 200ms ease-out;
  p {
    font-style: normal !important;
    color: #ffffff !important;
  }
  a {
    color: #ffffff !important;
    font-style: normal !important;
  }
`

const ModalTitle = styled.h1`
    background: ${colors.mustikka} !important;
    width: 100%;
    padding: 0.3em;
    font-size: 3em;
    line-height: 0.9em;
    color: #ffffff !important;
    text-transform: uppercase;
    text-align: center;
    font-weight: 900;
    box-sizing: border-box;
    margin: 1.5em 0 0 0;
    @media (min-width: 769px) {
        font-size: 5em;
    }
`

const ModalContent = styled.div`
    h1, h2, h3, h4, h5, h6 {
        color: ${colors.mustikka} !important;
    }
`

const CloseButton = styled.button`
    border: none;
    appearance: none;
    width: 3em;
    height: 3em;
    display: block;
    position: fixed;
    top: 2em;
    right: 2em;
    background: transparent url(${closeIcon}) no-repeat center center;
    background-size: contain;
    cursor: pointer;
    z-index: 31;
`

export default function({title, content, isOpen, setOpen}) {
  const onClose = () => setOpen(false)
  return <TranslationContext.Consumer>{(t) =>
    <StyledModal isOpen={isOpen} onBackgroundClick={onClose} onEscapeKeydown={onClose}>
      <ModalTitle>{t(title)}</ModalTitle>
      <ModalContent>
        {content.map((item, i) => <TextBlock key={i} {...item} />)}
      </ModalContent>
      <CloseButton onClick={onClose} />
    </StyledModal>
  }</TranslationContext.Consumer>
}

export const query = graphql`
  fragment Popup on ContentfulPopup {
    title
    content {
        ...TextBlock
    }
  }
`
