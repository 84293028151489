import React from "react"
import styled from "styled-components"

export const colors = {
    havu: "#6d887d",
    mustikka: "#747dae",
    kaisla: "#b99874",
    pakuri: "#4a4b48",
    background: {
        White: "#ffffff",
        Light: "#f1eae3",
        Dark: "#333230",
        Dark97: "rgba(51,50,48,0.97)"
    }
}

export const Mobile = styled.div`
    @media (min-width: 769px) {
        display: none;
    }
`

export const Desktop = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`

export const Filler = styled.div`
    flex: 1;
`

export function WrappedWords({text}) {
    return text.split(" ").map((word, i) => <span key={i}>{word} </span>)
}
